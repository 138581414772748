import { IReportItem, IWagonsMapFeature } from "../types/wagons";

const groupByKey = (list: any[], key: string) =>
  list.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {});

const buildFeature = (properties: any, coordinates: [number, number]): IWagonsMapFeature => {
  return {
    type: "Feature",
    properties,
    geometry: { type: "Point", coordinates },
  };
};

const generatePreOffsetCoordinates = (count: number) => {
  const baseOffset = 0.00002;
  const size = Math.ceil(count / 4);

  const offset = baseOffset * size;

  const offsetCoordinates = [
    { lng: offset, lat: offset },
    { lng: offset, lat: -offset },
    { lng: -offset, lat: offset },
    { lng: -offset, lat: -offset },
  ];

  return offsetCoordinates[(count - 1) % 4];
};

export const mapWagonsReportToMap = (items: IReportItem[]) => {
  const locationGroup = groupByKey(items, "locationId");

  const mapItems: IWagonsMapFeature[] = [];

  if ("1" in locationGroup) {
    for (const item of locationGroup["1"])
      mapItems.push(
        buildFeature(
          {
            weight: item.weight,
            date: item.date,
            hasRfid: false,
            hasWarning: item.warning,
            containers: [
              {
                dumpings: [
                  {
                    vehicleEventId: item.vehicleEventId,
                    weight: item.weight,
                    date: item.date,
                    warning: item.warning,
                  },
                ],
              },
            ],
          },
          [item.long, item.lat]
        )
      );
  }

  for (const locationGroupId in locationGroup) {
    if (locationGroupId === "null") continue;
    let hasWarning = false;
    let dumpingsCount = 0;

    const { locationId, location, lat, long, vehicleEventId } = locationGroup[locationGroupId][0];

    const containerGroup = groupByKey(locationGroup[locationGroupId], "containerId");
    const containers = [];

    for (const containerGroupId in containerGroup) {
      const { containerId, containerType, rfid } = containerGroup[containerGroupId][0];

      containers.push({
        containerId,
        containerType,
        rfid,
        dumpings: containerGroup[containerGroupId].map(({ vehicleEventId, weight, date, warning }: any) => {
          if (warning) hasWarning = true;
          dumpingsCount += 1;
          return { vehicleEventId, weight, date, warning };
        }),
      });
    }

    mapItems.push(
      buildFeature(
        {
          vehicleEventId,
          locationId,
          location,
          hasWarning,
          dumpingsCount,
          hasRfid: true,
          containers,
        },
        [long, lat]
      )
    );
  }

  const uniqueCoordinates = new Set();
  const updatedMapItems: IWagonsMapFeature[] = [];

  const coordinatesCount: { [key: string]: number } = {};

  for (const item of mapItems) {
    const { coordinates } = item.geometry;
    const key = coordinates.join(",");

    if (uniqueCoordinates.has(key)) {
      // Duplicate coordinates found, update them slightly
      const [lng, lat] = coordinates;
      const count = coordinatesCount[key] ?? 1;

      const offset = generatePreOffsetCoordinates(count);
      const updatedLng = Number(lng) + offset.lng;
      const updatedLat = Number(lat) + offset.lat;

      item.geometry.coordinates = [updatedLng, updatedLat];
    }

    uniqueCoordinates.add(key);
    updatedMapItems.push(item);

    // Count the number of duplicates by coordinates
    coordinatesCount[key] = (coordinatesCount[key] ?? 0) + 1;
  }

  return updatedMapItems;
};
