import { IContainer } from "./container";
import { ISelectOption } from "./select";

export interface IUpdateIsPaidStatusRequest {
  locationId: number;
  isPaid: PaymentStatus;
  year: ISelectOption["value"];
}

export interface ICollectionPoint {
  locationId: number;
  administrationId: number;
  name: string;
  address: string;
  householdMembersCount: number;
  degreeOfSeparation: number;
  wastePerHouseholdMember: number | null;
  containers: IContainer[];
  isPO: boolean;
  invitationToken: null | string;
  invitationStatus: InvitiationStatus;
  isPaid: PaymentStatus;
  year: number;
}

export enum InvitiationStatus {
  NotInvited,
  Invited,
  Registered,
}

export enum PaymentStatus {
  Paid = 1,
  Unpaid,
  Moved,
}

export interface ICollectionPointDetailResponse {
  locationDetail: ICollectionPoint;
}

export interface IInviteToMobileAppResponse {
  token: string;
}

export interface IUpdateLocationIsPaidStatusResponse {
  updatedLocationId: number;
}
