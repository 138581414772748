import "./SalvageYardsPage.scss";

import { PageContainer } from "../../components/PageContainer";
import { MayorModulesType } from "../../types/mayorModules";

// ***** COMPONENT TO BE REFACTORED WHEN IT IS USED AGAIN *******

export const SalvageYardsPage = () => {
  // const dispatch = useDispatch();
  // const data = useSelector(getSalvageYards);
  // const { t } = useTranslation();

  // const [search, setSearch] = useState<Search>({
  //   fulltext: "",
  // });

  // const [pagination, setPagination] = useState<Pagination>({
  //   page: 1,
  //   descending: false,
  //   limit: 50,
  //   orderBy: "",
  // });

  // useEffect(() => {
  //   dispatch(getSalvageYardsList(search, pagination));
  // }, [dispatch, pagination, search]);

  // const hadleNextPage = () => {
  //   const page = pagination.page + 1;
  //   setPagination({ ...pagination, page });
  // };

  // const handleChange = (event: any) => {
  //   setSearch({ fulltext: event.target.value });
  // };

  // const handleSearch = () => {
  //   dispatch(getSalvageYardsList(search, pagination));
  // };

  // const handleKeyPress = (event: any) => {
  //   if (event.key === "Enter") {
  //     handleSearch();
  //   }
  // };

  //return (
  //<>
  //[> <div className="SalvageYardsPage__title-segment pageHeader">
  //<div>
  //<h2>{t("savlageYards")} | </h2>
  //</div>
  //</div>
  //<div className="SalvageYardsPage__container pageContainer">
  //<div className="SalvageYardsPage__container__top-segment">
  //<div>
  //<SearchBar
  //value={search.fulltext}
  //onChange={handleChange}
  //onSearch={handleSearch}
  //onKeyDown={handleKeyPress}
  ///>
  //</div>
  //</div>
  //<SalvageYardsTable items={data} hadleNextPage={hadleNextPage} />
  //</div> */}
  //[> <Footer /> <]
  //<NotYetImplemented />
  //<Image src="img/bg_ZBERNE_DVORY.jpeg" alt="zberne dvory" width="100%" height="100%" />
  //</>
  //);

  return <PageContainer module={MayorModulesType.SalvageYardModule}>NOT IMPLEMENTED</PageContainer>;
};
