import { forwardRef, ReactElement } from "react";
import { Button } from "@material-ui/core";

import { getModuleClassNames } from "../../utils/getModuleClassNames";
import { ButtonLoader } from "./ButtonLoader";

import styles from "./CustomButton.module.scss";

export enum IButtonWidth {
  small = "small",
  medium = "medium",
  large = "large",
}

export enum IButtonVariant {
  priamry = "primary",
  info = "info",
  neurtal = "neutral",
  important = "important",
  transparent = "transparent",
  textImportant = "textImportant",
}

interface ICustomButtonProps {
  startIcon?: ReactElement;
  text: string;
  variant?: IButtonVariant;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  showLoader?: boolean;
  disabled?: boolean;
}

export const CustomButton = forwardRef<any, ICustomButtonProps>(
  ({ startIcon, text, variant = IButtonVariant.priamry, className, onClick, showLoader, disabled }, ref) => {
    const appliedButtonClassNamesArray = [styles.customButton, styles[variant]];
    if (className) {
      appliedButtonClassNamesArray.push(className);
    }

    return (
      <Button
        onClick={onClick}
        startIcon={startIcon}
        variant="text"
        className={getModuleClassNames(appliedButtonClassNamesArray)}
        disableElevation
        disabled={disabled}
        ref={ref}
      >
        {showLoader ? <ButtonLoader /> : text}
      </Button>
    );
  }
);
