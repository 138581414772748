import { ReactFragment, ReactNode } from "react";
import { Tooltip as MuiTooltip } from "@mui/material";

import styles from "./Tooltip.module.scss";

interface TooltipProps {
  bgColor: string;
  content: ReactFragment;
  children?: ReactNode;
}

export const Tooltip: React.FC<TooltipProps> = ({ bgColor, content, children }) => {
  return (
    <MuiTooltip className={styles.tooltip} style={{ backgroundColor: bgColor }} title={content} arrow>
      {children ? <div>{children}</div> : <div className={styles.container}>i</div>}
    </MuiTooltip>
  );
};
