import { useSelector } from "react-redux";

import { getMayorModules } from "../selectors/mayorModules";
import { MayorModuleStatus,MayorModulesType } from "../types/mayorModules";

export const useGetModuleStatus = (module?: MayorModulesType): MayorModuleStatus => {
  if (!module) return MayorModuleStatus.active;
  const modules = useSelector(getMayorModules);
  return modules[module];
};
