import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { CustomButton, IButtonVariant } from "../../../components/CustomButton";
import { CustomModal } from "../../../components/CustomModal";
import { ImageNameEnum } from "../../../components/Image/ImageNameEnum";
import { ImageSuffixEnum } from "../../../components/Image/ImageSuffixEnum";
import { setCollectionPointIsPaidStatus } from "../../../reducers/collectionPointReducer";
import { updateLocationIsPaidStatusThunk } from "../../../reducers/collectionPointsReducer";
import { getSelectedYear } from "../../../selectors/application";
import { getCollectionPointDetail } from "../../../selectors/collectionPoint";
import { IUpdateIsPaidStatusRequest, PaymentStatus } from "../../../types/collectionPoint";
import { IModal } from "../../../types/modal";

import styles from "./PaymentStatusChangeConfirmationModal.module.scss";

interface PaymentStatusChangeConfirmationModalProps extends IModal {
  selectedIsPaidType: PaymentStatus;
}

export const PaymentStatusChangeConfirmationModal: React.FC<PaymentStatusChangeConfirmationModalProps> = ({
  isModalVisible,
  toggleModalVisibility,
  selectedIsPaidType,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { locationId } = useSelector(getCollectionPointDetail);
  const selectedYear = useSelector(getSelectedYear);
  const isPaid = selectedIsPaidType === PaymentStatus.Paid;

  const updateIsPaid: IUpdateIsPaidStatusRequest = {
    locationId,
    isPaid: selectedIsPaidType,
    year: selectedYear.value,
  };

  const handleUpdateIsPaid = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    dispatch(updateLocationIsPaidStatusThunk(updateIsPaid));
    dispatch(setCollectionPointIsPaidStatus(selectedIsPaidType));
    toggleModalVisibility();
  };

  return (
    <CustomModal
      isModalVisible={isModalVisible}
      toggleModalVisibility={toggleModalVisibility}
      imageName={isPaid ? ImageNameEnum.car : ImageNameEnum.carGrey}
      imageSuffix={ImageSuffixEnum.svg}
      title={isPaid ? "paymentStatusSetPaidModalTitle" : "paymentStatusSetUnpaidModalTitle"}
      text={isPaid ? "paymentStatusSetPaidModalText" : "paymentStatusSetUnpaidModalText"}
      contentGap={32}
      contentCenter={true}
    >
      <div className={styles.buttonsContainer}>
        <CustomButton
          text={t(isPaid ? "paymentStatusSetPaidModalButtonConfirm" : "paymentStatusSetUnpaidModalButtonConfirm")}
          onClick={handleUpdateIsPaid}
          variant={isPaid ? IButtonVariant.priamry : IButtonVariant.important}
        />
        <CustomButton
          text={t("paymentStatusChangeModalButtonDecline")}
          onClick={toggleModalVisibility}
          variant={IButtonVariant.neurtal}
        />
      </div>
    </CustomModal>
  );
};
