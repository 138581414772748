import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { GET_SALVAGE_YARDS_LIST_URL } from "../middleware/routes";
import { RootState } from "../store";
import { ISearch } from "../types/search";
import { ISalvageYardsState, Status } from "../types/state";
import { axiosRequest } from "../utils/axiosRequest";

export const defaultPagination = {
  page: 1,
  limit: 50,
};

export const defaultOrdering = {
  orderBy: "collectionYardId",
  descending: false,
};

const initSalvageYardsState: ISalvageYardsState = {
  status: Status.idle,
  errorMsg: null,
  yards: [],
  pagination: defaultPagination,
  ordering: defaultOrdering,
};

export const salvageYardsSlice = createSlice({
  name: "notification",
  initialState: initSalvageYardsState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetSalvageYardsState: () => {
      return initSalvageYardsState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSalvageYardsListThunk.pending, (state) => {
        state.status = Status.requesting;
      })
      .addCase(getSalvageYardsListThunk.fulfilled, (state, action) => {
        state.status = Status.success;
        state.yards = action.payload;
        state.errorMsg = null;
      })
      .addCase(getSalvageYardsListThunk.rejected, (state) => {
        state.status = Status.error;
        state.yards = [];
        state.errorMsg = "there has been an error";
      });
  },
});

export const getSalvageYardsListThunk = createAsyncThunk<any, ISearch, { state: RootState }>(
  "salvageYards/getSalvageYardsListThunk",
  async (search, { getState }) => {
    const state = getState();

    const requestBody = {
      fulltext: search.fulltext,
      pagination: state.salvageYards.pagination,
      ordering: state.salvageYards.ordering,
    };
    const response = await axiosRequest.post(GET_SALVAGE_YARDS_LIST_URL, requestBody);
    return response.data;
  }
);

export const { resetSalvageYardsState } = salvageYardsSlice.actions;
export const salvageYardsReducer = salvageYardsSlice.reducer;
