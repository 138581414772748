import { useTranslation } from "react-i18next";

import { CustomButton, IButtonVariant } from "../../../components/CustomButton";
import { CustomModal } from "../../../components/CustomModal";
import { IModal } from "../../../types/modal";

import styles from "./LeaveConfirmationModal.module.scss";

interface LeaveConfirmationModalProps extends IModal {
  handleContinue: () => void | any;
}

export const LeaveConfirmationModal: React.FC<LeaveConfirmationModalProps> = ({
  isModalVisible,
  toggleModalVisibility,
  handleContinue,
}) => {
  const { t } = useTranslation();

  return (
    <CustomModal
      isModalVisible={isModalVisible}
      toggleModalVisibility={toggleModalVisibility}
      title="updatesNotSaved"
      text="doYouWantToLeave"
      contentGap={32}
    >
      <div className={styles.buttonsContainer}>
        <CustomButton text={t("backToEditing")} onClick={toggleModalVisibility} />
        <CustomButton text={t("leaveWithoutSaving")} onClick={handleContinue} variant={IButtonVariant.neurtal} />
      </div>
    </CustomModal>
  );
};
