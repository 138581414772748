import { Redirect } from "react-router-dom";

import { useGetModuleStatus } from "../../hooks/useGetModuleStatus";
import { HOME_PAGE_PATH } from "../../middleware/routes";
import { MayorModuleStatus, MayorModulesType } from "../../types/mayorModules";
import { getModuleClassNames } from "../../utils/getModuleClassNames";
import { HeaderBackButton } from "../BackButton";
import { NotYetImplemented } from "../NotYetImplemented";
import { PremiumBanner } from "../PremiumBanner";

import styles from "./PageContainer.module.scss";

interface PageContainerProps {
  children: React.ReactNode;
  headerText?: string;
  HeaderComponent?: React.FC;
  fullHeight?: boolean;
  backOption?: boolean;
  backPath?: string;
  module?: MayorModulesType;
  hasDemo?: boolean;
  fullScreen?: boolean;
  displayPremium?: boolean;
}

export const PageContainer: React.FC<PageContainerProps> = ({
  children,
  headerText,
  HeaderComponent,
  fullHeight = true,
  backOption = false,
  backPath = "",
  module,
  hasDemo = false,
  fullScreen = false,
  displayPremium = false,
}) => {
  const appliedPageContainerClassNamesArray = [styles.pageContainer];
  const appliedPageContentClassNamesArray = [styles.pageContent];
  const status = useGetModuleStatus(module);
  if (fullHeight) {
    appliedPageContentClassNamesArray.push(styles.fullHeight);
  }
  if (fullScreen) {
    appliedPageContainerClassNamesArray.push(styles.fullScreen);
  }

  if (status === MayorModuleStatus.hidden) return <Redirect to={HOME_PAGE_PATH} />;
  if (status === MayorModuleStatus.inactive && !hasDemo) return <NotYetImplemented module={module} />;

  return (
    <div className={getModuleClassNames(appliedPageContainerClassNamesArray)}>
      <div className={styles.pageHeader}>
        {headerText && backOption && backPath && <HeaderBackButton backTo={backPath} title={headerText} />}
        {headerText && !backOption && <h2>{headerText}</h2>}
        {HeaderComponent && <HeaderComponent />}
      </div>
      <div className={styles.banner}>{displayPremium && <PremiumBanner />}</div>
      <div className={getModuleClassNames(appliedPageContentClassNamesArray)}>{children}</div>
    </div>
  );
};
