import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { HOME_PAGE_PATH, WASTE_COLLECTION_EDIT_PAGE_PATH } from "../../../middleware/routes";
import { getApplicationModeState } from "../../../selectors/application";
import { getUser } from "../../../selectors/auth";
import { iconTypeHandler } from "../../../utils/iconTypeHandler";
import { Icon, IconNameEnum, IconTypeEnum } from "../../Icon";
import { MenuItemWrapper } from "../MenuItemWrapper";

import styles from "./MenuItem.module.scss";

export interface IMenuItemProps {
  label: string;
  iconName: IconNameEnum;
  to?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  isActive?: boolean;
  isNew?: boolean;
}

export const MenuItem: React.FC<IMenuItemProps> = ({ label, iconName, to, onClick, isActive, isNew }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const applicationMode = useSelector(getApplicationModeState);
  const user = useSelector(getUser);
  const disabled = !user;

  const getActiveStatus = (): boolean => {
    let activeStatus = false;
    if ((pathname === HOME_PAGE_PATH || pathname === WASTE_COLLECTION_EDIT_PAGE_PATH) && to === HOME_PAGE_PATH) {
      activeStatus = true;
      return activeStatus;
    }
    if (
      to &&
      Boolean(pathname.slice(1, 5).startsWith(to.slice(1, 5)) || isActive) &&
      !disabled &&
      to === HOME_PAGE_PATH
    ) {
      return activeStatus;
    }

    if (to && Boolean(pathname.slice(1, 5).startsWith(to.slice(1, 5)) || isActive) && !disabled) {
      activeStatus = true;
    }

    return activeStatus;
  };

  const isWrapperActive = getActiveStatus();
  const iconType = iconTypeHandler(applicationMode, IconTypeEnum.light, IconTypeEnum.green, isWrapperActive);

  return (
    <MenuItemWrapper onClick={onClick} to={to} wrapperStyles={styles} disabled={disabled} isActive={isWrapperActive}>
      <div className={styles.container}>
        <Icon type={iconType} name={iconName} size="24px" height="24px" />
        <p className={styles.label}>{t(label)}</p>
      </div>
      {isNew && (
        <div className={styles.newLabel}>
          <p>{t("new")}</p>
        </div>
      )}
    </MenuItemWrapper>
  );
};
