import { IconNameEnum } from "../../components/Icon";
import {
  COLLECTION_POINTS_PAGE_PATH,
  INVENTORY_SHEET_PAGE_PATH,
  MUNICIPALITY_STATE_PAGE_PATH,
  SALVAGE_YARDS_PAGE_PATH,
  SUPPORT_EMAIL,
  WAGONS_CALENDAR_PAGE_PATH,
  WAGONS_PAGE_PATH,
} from "../../middleware/routes";
import { BottomMayorModulesType, IMayorModulesObject, TopMayorModulesType } from "../../types/mayorModules";
import { MenuItemLabelsEnum } from "./menuItemLabelsEnum";

const handleEmailToHelpdesk = () => {
  window.location.href = `mailto:${SUPPORT_EMAIL}`;
};

export const modules: IMayorModulesObject = {
  [TopMayorModulesType.CollectionPointModule]: {
    label: MenuItemLabelsEnum.collectionPoints,
    iconName: IconNameEnum.location,
    to: COLLECTION_POINTS_PAGE_PATH,
  },
  [TopMayorModulesType.WagonModule]: {
    label: MenuItemLabelsEnum.wagons,
    iconName: IconNameEnum.truck,
    to: WAGONS_PAGE_PATH,
    isNew: true,
  },
  CommunityStateModule: {
    label: MenuItemLabelsEnum.communityState,
    iconName: IconNameEnum.flag,
    to: MUNICIPALITY_STATE_PAGE_PATH,
  },
  [TopMayorModulesType.SalvageYardModule]: {
    label: MenuItemLabelsEnum.salvageYard,
    iconName: IconNameEnum.arrows,
    to: SALVAGE_YARDS_PAGE_PATH,
  },
  [TopMayorModulesType.WagonCalendarModule]: {
    label: MenuItemLabelsEnum.wagonsCalendar,
    iconName: IconNameEnum.calendar,
    to: WAGONS_CALENDAR_PAGE_PATH,
  },
  [TopMayorModulesType.InventorySheetModule]: {
    label: MenuItemLabelsEnum.inventorySheet,
    iconName: IconNameEnum.paper,
    to: INVENTORY_SHEET_PAGE_PATH,
  },
  [BottomMayorModulesType.SupportModule]: {
    label: MenuItemLabelsEnum.helpdesk,
    iconName: IconNameEnum.helpdeskMessage,
    onClick: handleEmailToHelpdesk,
  },
};
