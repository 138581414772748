import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { Icon, IconNameEnum, IconTypeEnum } from "../../../components/Icon";
import { PaymentStatus } from "../../../types/collectionPoint";

interface IIsPaidButtonProps {
  params: GridRenderCellParams<PaymentStatus>;
}

export const PaymentStatusIndicator: React.FC<IIsPaidButtonProps> = ({ params }) => {
  return (
    <div>
      {params.value === PaymentStatus.Paid ? (
        <Icon type={IconTypeEnum.green} name={IconNameEnum.check} size="14px" />
      ) : (
        <Icon type={IconTypeEnum.red} name={IconNameEnum.cross} size="14px" />
      )}
    </div>
  );
};
