import React from "react";

import { useModal } from "../../hooks/useModal";
import { CustomButton, IButtonVariant } from "../CustomButton";
import { CustomModal } from "../CustomModal";

import styles from "./DeleteDialog.module.scss";

interface DeleteDialogProps {
  openComponent: React.ReactNode;
  onDelete: () => void;
  title: string;
  text: string;
  confirmText: string;
  cancelText: string;
}

const DeleteDialog: React.FC<DeleteDialogProps> = ({
  onDelete,
  openComponent,
  title,
  text,
  confirmText,
  cancelText,
}) => {
  const inviteToMobileAppModal = useModal();

  const handleSubmit = () => {
    inviteToMobileAppModal.toggleModalVisibility();
    onDelete();
  };

  return (
    <div>
      <div className={styles.openComponent} onClick={inviteToMobileAppModal.toggleModalVisibility}>
        {openComponent}
      </div>
      <CustomModal
        isModalVisible={inviteToMobileAppModal.isModalVisible}
        toggleModalVisibility={inviteToMobileAppModal.toggleModalVisibility}
        title={title}
        text={text}
      >
        <div className={styles.formContainer}>
          <section className={styles.actions}>
            <CustomButton variant={IButtonVariant.important} onClick={handleSubmit} text={confirmText} />
            <CustomButton
              variant={IButtonVariant.neurtal}
              onClick={inviteToMobileAppModal.toggleModalVisibility}
              text={cancelText}
            />
          </section>
        </div>
      </CustomModal>
    </div>
  );
};

export default DeleteDialog;
