import { combineReducers } from "redux";

import { applicationReducer } from "./applicationReducer";
import { authReducer } from "./authReducer";
import { collectionPointReducer } from "./collectionPointReducer";
import { collectionPointsReducer } from "./collectionPointsReducer";
import { inventorySheetReducer } from "./inventorySheetReducer";
import { locationReducer } from "./locationReducer";
import { mayorModulesReducer } from "./mayorModulesReducer";
import { notificationReducer } from "./notificationReducer";
import { salvageYardsReducer } from "./salvageYardsReducer";
import { settingsReducer } from "./settingsReducer";
import { wagonEventCreateReducer } from "./wagonEventCreateReducer";
import { wagonEventDetailReducer } from "./wagonEventDetailReducer";
import { wagonsCalendarReducer } from "./wagonsCalendarReducer";
import { wagonsDatePickerReducer } from "./wagonsDatePickerReducer";
import { wagonsReportReducer } from "./wagonsReportStateReducer";
import { wasteTypesQuantityReducer } from "./wasteTypesQuantityReducer";
import { weightHistoryReducer } from "./weightHistoryReducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  application: applicationReducer,
  settings: settingsReducer,
  collectionPoints: collectionPointsReducer,
  collectionPoint: collectionPointReducer,
  notification: notificationReducer,
  salvageYards: salvageYardsReducer,
  weightHistory: weightHistoryReducer,
  wasteTypesQuantity: wasteTypesQuantityReducer,
  location: locationReducer,
  wagonsCalendar: wagonsCalendarReducer,
  wagonEventCreate: wagonEventCreateReducer,
  wagonEventDetail: wagonEventDetailReducer,
  mayorModules: mayorModulesReducer,
  wagonsDatePicker: wagonsDatePickerReducer,
  wagonsReport: wagonsReportReducer,
  inventorySheet: inventorySheetReducer,
});
