import { isAnyOf } from "@reduxjs/toolkit";
import i18n from "i18next";

import { getApplicationEnumsThunk, setLoaderVisibility } from "../../reducers/applicationReducer";
import { loginUserThunk, setUserWithJwtThunk } from "../../reducers/authReducer";
import { cleanUpCollectionPointDetail, getCollectionPointDetailThunk } from "../../reducers/collectionPointReducer";
import { getCollectionPointsListThunk } from "../../reducers/collectionPointsReducer";
import {
  getInventorySheetListThunk,
  getInventorySheetSummaryDefaultThunk,
  getInventorySheetSummaryThunk,
} from "../../reducers/inventorySheetReducer";
import { getLocationDoughnutChartDataThunk, getLocationSummaryThunk } from "../../reducers/locationReducer";
import { getMayorModulesSettings } from "../../reducers/mayorModulesReducer";
import { cleanUpWagonEventDetail, getWagonEventDetailThunk } from "../../reducers/wagonEventDetailReducer";
import { getWagonsCalendarThunk } from "../../reducers/wagonsCalendarReducer";
import { getWasteTypesQuantityYearThunk } from "../../reducers/wasteTypesQuantityReducer";
import { getWeightHistoryThunk } from "../../reducers/weightHistoryReducer";
import { getQueryString } from "../../utils/getQueryString";
import {
  COLLECTION_POINT_PAGE_PATH,
  CONTAINER_DETAIL_PAGE_PATH,
  DETAIL_WAGON_EVENT_PAGE_PATH,
  WEIGHT_HISTORY_PAGE_PATH,
} from "../routes";
import { startAppListening } from "./listenerMiddleware";
import { getPromiseAllArray } from "./utils/getPromiseAllArray";

export const initialDataLoadListener = (): void => {
  startAppListening({
    matcher: isAnyOf(loginUserThunk.fulfilled, setUserWithJwtThunk.fulfilled),
    effect: async (action, { dispatch }) => {
      const language = action.payload.language;
      i18n.changeLanguage(language);
      const pathname = location.pathname;
      dispatch(getCollectionPointsListThunk());
      dispatch(getWagonsCalendarThunk());
      dispatch(setLoaderVisibility(true));
      dispatch(getInventorySheetListThunk());
      dispatch(getInventorySheetSummaryThunk());
      dispatch(getInventorySheetSummaryDefaultThunk());

      let actionsArray: any[] = [
        getApplicationEnumsThunk(),
        getLocationSummaryThunk(),
        getLocationDoughnutChartDataThunk(),
        getWasteTypesQuantityYearThunk(),
        getMayorModulesSettings(),
      ];

      switch (pathname) {
        case COLLECTION_POINT_PAGE_PATH:
          {
            const collectionPointId = Number(getQueryString("id"));
            dispatch(cleanUpCollectionPointDetail());
            actionsArray = [...actionsArray, getCollectionPointDetailThunk(collectionPointId)];
          }
          break;
        case WEIGHT_HISTORY_PAGE_PATH:
          {
            const collectionPointId = Number(getQueryString("id"));
            const containerId = getQueryString("cid");
            dispatch(cleanUpCollectionPointDetail());
            actionsArray = [
              ...actionsArray,
              getCollectionPointDetailThunk(collectionPointId),
              getWeightHistoryThunk(Number(containerId)),
            ];
          }
          break;
        case DETAIL_WAGON_EVENT_PAGE_PATH:
          {
            const wagonEventId = Number(getQueryString("id"));
            dispatch(cleanUpWagonEventDetail());
            actionsArray = [...actionsArray, getWagonEventDetailThunk(wagonEventId)];
          }
          break;
        case CONTAINER_DETAIL_PAGE_PATH:
          {
            const collectionPointId = getQueryString("id");
            const containerId = getQueryString("cid");

            actionsArray = [
              ...actionsArray,
              getCollectionPointDetailThunk(Number(collectionPointId)),
              getWeightHistoryThunk(Number(containerId)),
            ];
          }
          break;
        default:
          break;
      }

      const promiseAllArray = await getPromiseAllArray(actionsArray, dispatch);

      const isAllActionsFulfilled = promiseAllArray.every((action: any) => action.meta.requestStatus === "fulfilled");
      if (isAllActionsFulfilled) {
        dispatch(setLoaderVisibility(false));
      }
    },
  });
};
