export const API_URL_OLD = process.env.REACT_APP_API_URL || "https://dev.ewama.koala42.com";
export const API_URL = process.env.REACT_APP_CITIZEN_API_URL || "https://dev.ewama.citizen.api.koala42.com/v1";

export const GET_APPLICATION_ENUMS_URL = "/enums";

export const POST_LOGIN_URL = "/users/login";
export const GET_CURRENT_USER = "/users";
export const UPDATE_LANGUAGE_URL = "/users";

export const GET_LOCATION_SUMMARY_URL = "/administrations/detail";

export const GET_LOCATION_WASTE_TYPES_QUANTITY_URL = "/waste-summaries/get-detail";
export const GET_LOCATION_DOUGHNUT_CHART_DATA_URL = "/waste-summaries/shares";

export const CREATE_LOCATION_WASTE_TYPES_QUANTITY_URL = "/waste-summaries/create";
export const UPDATE_LOCATION_WASTE_TYPES_QUANTITY_URL = "/waste-summaries/update";

export const GET_CONTAINERS_INFO_URL = "/containers/detail";
export const PATCH_CONTAINERS_INFO_URL = "/containers/detail";

export const GET_COLLECTION_POINTS_LIST_URL = "/locations/list";
export const UPDATE_IS_PAID_STATUS_URL = "/location/update-ispaid";
export const GET_COLLECTION_POINTS_XLSX_DATA = "/locations/xlsx-data";

export const GET_INVENTORY_SHEET_LIST_URL = "/inventory-sheet/list";
export const GET_INVENTORY_SHEET_SUMMARY_URL = "/inventory-sheet/summary";

export const GET_COLLECTION_POINT_DETAIL_URL = "/locations/detail";
export const POST_INVITE_TO_MOBILE_APP_URL = "/v1/owners/invite";
export const POST_COLLECTION_POINT_URL = "/location/create";
export const POST_COLLECTION_POINT_EXCEL_URL = "/location/excel-import";
export const GET_WEIGHT_HISTORY_URL = "/locations/container";

export const GET_SALVAGE_YARDS_LIST_URL = "/salvage-yard/get-list";

export const WAGON_CALENDAR_URL = "/v1/waste-collection-calendar";

export const SUPPORT_EMAIL = "info@wama.sk";

export const GET_MAYOR_MODULES = "/administrations/modules";

export const GET_WAGONS_CALENDAR_PINS = "/waste-collections/calendar";
export const GET_WAGONS_REPORT = "/waste-collections/report";

export const LOGIN_PAGE_PATH = "/login";
export const COLLECTION_POINTS_PAGE_PATH = "/collection-points";
export const COLLECTION_POINT_PAGE_PATH = "/collection-point-detail";
export const WEIGHT_HISTORY_PAGE_PATH = "/collection-point-detail/weight-history";
export const SALVAGE_YARDS_PAGE_PATH = "/salvage-yard";
export const WAGONS_PAGE_PATH = "/wagons";
export const INVENTORY_SHEET_PAGE_PATH = "/inventory-sheet";
export const CONTAINER_DETAIL_PAGE_PATH = "/collection-point-detail/container-detail";
export const WAGONS_CALENDAR_PAGE_PATH = "/calendar";
export const DETAIL_WAGON_EVENT_PAGE_PATH = "/calendar-detail";
export const CREATE_WAGON_EVENT_PAGE_PATH = "/calendar-create";
export const MUNICIPALITY_STATE_PAGE_PATH = "/municipality-state";
export const WASTE_COLLECTION_EDIT_PAGE_PATH = "/waste-collection/edit";
export const LANGUAGE_SETTINGS_PAGE = "/language";
export const HOME_PAGE_PATH = "/";
